import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function generateQuiz(): [string, string] {
  const number1 = Math.floor(Math.random() * 9) + 1;
  const number2 = Math.floor(Math.random() * 9) + 1;
  const answer = number1 * number2;
  return [`${number1} × ${number2}`, `${answer}`];
}

const App: React.FC = () => {
  const [quiz, setQuiz] = React.useState('');
  const [answer, setAnswer] = React.useState('');
  const [opened, setOpened] = React.useState(false);

  const resetQuiz = () => {
    const [q, a] = generateQuiz();
    setQuiz(q);
    setAnswer(a);
    setOpened(false);
  };

  const updateQuiz = () => {
    if (opened) {
      resetQuiz();
    } else {
      setOpened(true);
    }
  };

  React.useEffect(() => {
    resetQuiz();
  }, []);

  if (quiz === '') {
    resetQuiz();
  }

  return (
    <Grid
      container
      spacing={2}
      alignItems='center'
      justifyContent='center'
      direction='column'
    >
      <Grid item xs={12}>
        <p>九九のれんしゅう</p>
      </Grid>
      <Grid item xs={12} sx={{ display: 'grid', gridAutoRows: '4rem', gap: 1 }}>
        <Typography align='center' sx={{ fontSize: 48, fontWeight: 700 }}>
          {quiz}
        </Typography>
        <Typography align='center'>{opened ? `${answer}` : ''}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Button startIcon={<NavigateNextIcon />} onClick={updateQuiz}>
            すすむ
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default App;
